import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  DatePicker,
  TimePicker,
  InputNumber,
  Button,
  Switch,
  Table,
  Modal,
  Popconfirm,
  Tooltip,
} from 'antd'
import moment from 'moment'
import uuid from 'uuid'
import { DeleteOutlined } from '@ant-design/icons'

import {
  updateSeances,
} from '../../../../redux/actions/TicketsActions'

class TemporarySeancesTable extends PureComponent {
  state = {
    isOpenModal: false,
    seances: [],
  }

  componentDidMount() {
    this.createSeance = this.createSeance.bind(this)
    this.updateSeance = this.updateSeance.bind(this)
    this.deleteSeance = this.deleteSeance.bind(this)
  }

  renderItemDate(item) {
    let dateS = moment(item.date_s, 'YYYY-MM-DD HH:mm:ss')
    let dateE = moment(item.date_e, 'YYYY-MM-DD HH:mm:ss')

    if (dateS.format('DD.MM.YYYY') === dateE.format('DD.MM.YYYY')) {
      return `${dateS.format('DD.MM.YYYY')} с ${dateS.format('HH:mm')} до ${dateE.format('HH:mm')}`
    }

    return `с ${dateS.format('DD.MM.YYYY HH:mm')} до ${dateE.format('DD.MM.YYYY HH:mm')}`
  }

  createSeance() {
    this.setState({
      seances: [
        ...this.state.seances,
        {
          id: uuid(),
          date_s: moment().add(1, 'day').format('YYYY-MM-DD 12:00:00'),
          date_e: moment().add(1, 'day').format('YYYY-MM-DD 13:00:00'),
          sales_limit: 100,
          sold_count: 0,
          booked_count: 0,
          is_available_for_sale: 1
        }
      ],
    })
  }

  updateSeance(seanceId, field, value, callback = () => { }) {
    const { seances } = this.state

    const seanceIndex = seances.findIndex(
      item => item.id === seanceId
    )

    if (seanceIndex >= 0) {
      let seancesCopy = seances.slice()
      seancesCopy[seanceIndex] = Object.assign({}, seancesCopy[seanceIndex])
      seancesCopy[seanceIndex][field] = value
      this.setState({ seances: seancesCopy }, callback)
    }
  }

  deleteSeance(seanceId) {
    const { seances } = this.state

    const seanceIndex = seances.findIndex(item => item.id === seanceId)

    if (seanceIndex >= 0) {
      let seancesCopy = seances.slice()
      seancesCopy.splice(seanceIndex, 1)
      this.setState({ seances: seancesCopy })
    }
  }

  render() {
    const ticketForm = this.props.tickets.ticketForm
    const { seances } = this.state

    const columns = [
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        width: 160,
      },
      {
        title: 'Начало',
        dataIndex: 'time_s',
        key: 'time_s',
      },
      {
        title: 'Окончание',
        dataIndex: 'time_e',
        key: 'time_e',
      },
      {
        title: 'Поступило в продажу',
        dataIndex: 'sales_limit',
        key: 'sales_limit',
      },
      {
        title: 'Продано',
        dataIndex: 'sold_count',
        key: 'sold_count',
      },
      {
        title: 'Активен',
        dataIndex: 'is_available_for_sale',
        key: 'is_available_for_sale',
      },
      {
        title: '',
        key: 'action',
        width: 30,
        render: (text, record) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={e => this.deleteSeance(record.id)}
            okText="Да"
            cancelText="Нет"
            disabled={record.booked_count > 0}
          >
            <a href="/" style={record.booked_count > 0 ? { opacity: 0.5, cursor: 'not-allowed' } : {}}>
              <Tooltip placement="top" title={record.booked_count > 0 ? "Для этого сеанса есть заказанные билеты, удаление невозможно" : ""}>
                <DeleteOutlined />
              </Tooltip>
            </a>
          </Popconfirm >
        ),
      },
    ]

    const data = seances.map(item => {
      let disableFields = item.booked_count > 0

      return {
        key: item.id,
        id: item.id,
        date: (
          <DatePicker
            format="DD.MM.YYYY"
            onChange={(date, dateStr) => {
              let dateSStr = moment(item.date_s, 'YYYY-MM-DD HH:mm:ss')
                .year(date.year())
                .month(date.month())
                .date(date.date())
                .format('YYYY-MM-DD HH:mm:ss')

              let dateEStr = moment(item.date_e, 'YYYY-MM-DD HH:mm:ss')
                .year(date.year())
                .month(date.month())
                .date(date.date())
                .format('YYYY-MM-DD HH:mm:ss')

              this.updateSeance(item.id, 'date_s', dateSStr, () => {
                this.updateSeance(item.id, 'date_e', dateEStr)
              })
            }}
            value={moment(item.date_s, 'YYYY-MM-DD HH:mm:ss')}
            disabled={disableFields}
          />
        ),
        time_s: (
          <TimePicker
            format="HH:mm"
            onChange={(date, timeStr) => {
              this.updateSeance(
                item.id,
                'date_s',
                date.format('YYYY-MM-DD HH:mm:ss'),
                () => {
                  if (date > moment(item.date_e, 'YYYY-MM-DD HH:mm:ss')) {
                    this.updateSeance(item.id, 'date_e', date.add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'))
                  }
                }
              )
            }}
            value={moment(item.date_s, 'YYYY-MM-DD HH:mm:ss')}
            disabled={disableFields}
          />
        ),
        time_e: (
          <TimePicker
            format="HH:mm"
            onChange={(date, timeStr) => {
              this.updateSeance(
                item.id,
                'date_e',
                date.format('YYYY-MM-DD HH:mm:ss'),
                () => {
                  if (date <= moment(item.date_s, 'YYYY-MM-DD HH:mm:ss')) {
                    this.updateSeance(item.id, 'date_s', date.add(-1, 'hour').format('YYYY-MM-DD HH:mm:ss'))
                  }
                }
              )
            }}
            value={moment(item.date_e, 'YYYY-MM-DD HH:mm:ss')}
            disabled={disableFields}
          />
        ),
        sales_limit: (
          <InputNumber
            min={1}
            max={10000}
            onChange={(value) => this.updateSeance(item.id, 'sales_limit', value)}
            value={item.sales_limit}
            disabled={disableFields}
          />
        ),
        sold_count: item.sold_count,
        booked_count: item.booked_count,
        is_available_for_sale: (
          <Switch
            checked={item.is_available_for_sale === 1}
            onChange={(value) =>
              this.updateSeance(item.id, 'is_available_for_sale', value ? 1 : 0)
            }
          />
        ),
      }
    })

    return (
      <>
        {
          ticketForm.seances.length > 0 && (
            <div className="TemporarySeancesTableComponent__list-inner">
              <ul className="TemporarySeancesTableComponent__list">
                {
                  ticketForm.seances.map((item, index) =>
                    <li key={index} style={{ opacity: item.is_available_for_sale === 1 ? 1 : 0.5 }}>
                      {this.renderItemDate(item)}
                    </li>
                  )}
              </ul>
            </div>
          )
        }
        <Button onClick={
          () => this.setState({
            isOpenModal: true,
            seances: ticketForm.seances
          })
        }>
          Настроить расписание
        </Button>
        <Modal
          visible={this.state.isOpenModal}
          okText="Сохранить"
          cancelText="Закрыть"
          onOk={() => {
            this.props.updateSeances(seances)
            this.setState({ isOpenModal: false })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
          width={900}
        >
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            style={{ marginTop: '1.25rem', marginBottom: '1rem' }}
          />
          <Button onClick={this.createSeance} disabled={seances.length >= 100}>
            Добавить
          </Button>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    tickets: store.tickets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSeances: (seances) => dispatch(updateSeances(seances)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemporarySeancesTable)